<template>
	<div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Defina as categorias e subcategorias disponíveis no sistema: adicione, atualize ou delete, útil para facilitar a pesquisa de produtos.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div class="col-12">
			<div class="card mb-2">
            <div class="card-body">
               <div class="row">
                  <div class="col">
							<label><i class="far fa-file color-theme font-12 me-1"></i> Filtrar</label>
                     <input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus placeholder="Busque por nome da categoria ou subcategoria" />
                  </div>
                  <div class="w-max-content align-self-end" v-if="dadosUsuario.usuarioPermissoes.includes('Produtos | Editar categorias')">
							<button type="button" class="btn btn-primary w-100 px-sm-5" @click="abrirModal(null)"><i class="far fa-plus font-13 me-1"></i> Adicionar</button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12 px-0" v-if="pesquisa.resultado.length > 0">
			<div class="row categorias">
				<categoria v-for="(categoria, index) in pesquisa.resultado" :key="index" :categoria="categoria" :index="index" @editar="abrirModal($event)" />
			</div>
      </div>

      <!-- modalCategoria -->
      <div class="modal fade" id="modalCategoria" tabindex="-1" aria-labelledby="modalCategoriaLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalCategoriaLabel">{{ modal.id == null ? 'Adicionar' : 'Editar' }} categoria {{ modal.id == null ? '' : ('('+ modal.nome +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card mb-2">
							<div class="card-body p-12">
								<div class="row">
									<div class="col-12 mb-3">
										<label><i class="far fa-file color-theme font-12 me-1"></i> Nome da categoria *</label>
										<input type="text" class="form-control" v-model="modal.nome" maxlength="200" />
									</div>

									<uploadImagem :resultado="modal.banner" :ratio="'1x1'" label="Banner" widthCropper="col-12" @set="modal.banner = $event;" />
								</div>
							</div>
						</div>

						<div class="card mb-2">
							<div class="card-body p-12">
								<div class="row">
									<div class="col-12 mb-3">
										<h6 class="mb-0 mt-1 font-15">
											<span><i class="far fa-copy color-theme font-13 me-2"></i>Subcategorias *</span>
											<a href="javascript:;" class="float-end font-16" data-bs-toggle="modal" data-bs-target="#modalSubcategoria"><i class="fas fa-plus color-theme"></i></a>
										</h6>
									</div>

									<subcategoria v-for="(subcategoria, index) in modal.subcategorias" :key="index" :subcategoria="subcategoria" :index="index" 
										@remover="removerSubcategoria($event)" />
										
									<div class="col-12 my-5 text-center" v-if="modal.subcategorias.length == 0">
										<i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
										<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Clique no ícone <i class="far fa-plus mx-1"></i> para adicionar</h4>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="salvarCategoria">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

		<!-- modalSubcategoria -->
      <div class="modal fade" id="modalSubcategoria" tabindex="-1" aria-labelledby="modalSubcategoriaLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalSubcategoriaLabel">Adicionar subcategoria</h5>
                  <button type="button" class="btn-close" data-bs-toggle="modal" data-bs-target="#modalCategoria" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card">
							<div class="card-body p-12">
								<label><i class="far fa-copy color-theme font-11 me-1"></i> Nome *</label>
								<input class="form-control" v-model="modal.subcategoria.nome" maxlength="200" />
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="addSubcategoria">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#modalCategoria">Voltar</button>
               </div>
				</div>
			</div>
		</div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import uploadImagem from '@/components/modelos/UploadImagem.vue'
import categoria from '@/components/produtos/categorias/Categoria.vue'
import subcategoria from '@/components/produtos/categorias/Subcategoria.vue'

export default {
	name: 'Categorias',
	data: function () {
		return {
			pesquisa: {'nome': null, 'resultado': []},
			modal: {'id': null, 'nome': null, 'subcategorias': [], 'banner': null, 'subcategoria': {'id': null, 'nome': ''}},
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function () {
			this.buscarCategorias()
      },
      'pesquisa.nome' : function (val) {
         var value = val.toLowerCase()

         $(".row.categorias > *").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
         })
      }
   },
	components: {
		categoria, subcategoria, uploadImagem
	},
	methods: {
		abrirModal : function (categoria) {
			if (categoria == null) {
				Object.assign(this.$data.modal, this.$options.data.apply(this).modal)
			} else {
				this.modal = {
					'id': categoria.id, 
					'nome': categoria.nome, 
					'subcategorias': JSON.parse(JSON.stringify(categoria.subcategorias)), 
					'banner': categoria.banner,
					'subcategoria': {'id': null, 'nome': ''}
				}
			}

			$('#modalCategoria').modal('show')
		},
		buscarCategorias : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			this.$store.dispatch('getCategorias').then((data) => {
				this.pesquisa.resultado = data
			}).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
		},
		addSubcategoria : function () {
			if (this.modal.subcategoria.nome == null || String(this.modal.subcategoria.nome).trim().length == 0) {
				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			this.modal.subcategorias.push(JSON.parse(JSON.stringify(this.modal.subcategoria)))
			this.modal.subcategoria = {'id': null, 'nome': ''}
			$('#modalSubcategoria').modal('hide')
			$('#modalCategoria').modal('show')
		},
		removerSubcategoria : function (index) {
			this.modal.subcategorias.splice(index, 1)
		},
		salvarCategoria : function () {
			this.$store.dispatch('base64toFile', Array.of(this.modal.banner)).then((banner) => {
				if (this.modal.nome == null || String(this.modal.nome).trim().length == 0 || this.modal.subcategorias.length == 0) {
					this.$toast.fire({
						icon: 'error',
						title: 'Preencha todos os campos obrigatórios'
					});

					return
				}

				this.$store.dispatch('alternarTelaCarregamento', true)

				let dataForm = new FormData();
				dataForm.append('info', JSON.stringify(this.modal));

				if (banner[0] instanceof File) {
					dataForm.append('banner', banner[0], String(banner[0].name).replace(/[^\w\s.]/gi, ''));
				}

				this.$axios({
					method: 'post',
					url: this.urlRest +'produtos/saveCategoria',
					headers: {'Content-Type': 'multipart/form-data'},
					data: dataForm

				}).then(() => {
					$('#modalCategoria').modal('hide')
					this.buscarCategorias()

					this.$toast.fire({
						icon: 'success',
						title: 'Categoria salva!'
					});
				}).catch((error) => {
					if (error.response != undefined) {
						if (error.response.status == 408) {
							this.$store.dispatch('deslogar')

							this.$toast.fire({
								icon: 'warning',
								title: 'Sessão expirada!'
							});
						} else if (error.response.status == 409) {
							this.$toast.fire({
								icon: 'error',
								title: 'Nome da categoria já existente!'
							});
						} else {
							this.$toast.fire({
								icon: 'error',
								title: 'Erro: ' + error.response.status
							});
						}
					}
				}).finally(() => {
					this.$store.dispatch('alternarTelaCarregamento', false)
				});
			});
		}
	},
	mounted() {
      this.buscarCategorias()
	}
}

</script>